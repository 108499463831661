export const howToCatchAStar = `How to {verb#1} a {noun#1}s

Once there was a boy, and the boy loved {noun#1}s very much. Every night the boy {past_verb#1} the {noun#1} from {place#1} and wished he had one of his very own. He dreamed how this {noun#1} might be his friend. They would play {game} and take long walks together. The boy decided he would try to {verb#1} a {noun#1}. He thought that getting up early in the morning would be best, because then the {noun#1} would be {feeling#1} from being up in {place#2} all night. So, the next day he set out at sunrise. But he could not see {noun#1}s anywhere. He sat down and {past_verb#1} for one to appear.

He {past_verb#1}... and he {past_verb#2}... and ate lunch...

...and {past_verb#1}. And after dinner, he {past_verb#1} some more. Finally, just before the sun was about to go away, he saw a {noun#1}. They boy tried to {verb#2} and {verb#4} it. But he could not {verb#2} high enough. So, very carefully he climbed to the top of the tallest {noun#2} he could find. But the {noun#1} was still way out of reach. He thought he might {verb#3} the {noun#1} with the {noun#3} from his father's {noun#4}. But it was much too {adjective#1} for him to carry. He thought he could fly up in his {vehicle#1} and just grab the {noun#1} but his {vehicle#1} had run out of {plural_noun#1} last {holiday} when he went to {place#3}.

Perhaps he could get a {animal#1} to help him {verb#5} up into the sky to reach his {noun#1}? But the only {animal#1} he could find didn't want to help at all. The boy thought he would never {verb#1} a {noun#1}. Just then he noticed something floating in the water. It was the prettiest {noun#1} he had ever seen. Just a baby {noun#1}. It must have fallen from the sky. He tried to fish the {noun#1} out with his {bodypart#1}. But he couldn't reach it. Then he had an idea. The {noun#1} might wash up on the shore. He ran back along the jetty to the beach. Then he {past_verb#1} and {past_verb#2}...

... and {past_verb#2} and {past_verb#1}...

...and, sure enough, the {noun#1} washed up on the bright, {colour#1} sand. The boy had caught a {noun#1}. A {noun#1} of his very own.`;
