export const africaDogs = `{city#1}'s wild {plural_animal#1} are in danger of dying out, but researchers think they may have found a way to save them {liquid#1}.

Wild {plural_animal#1} were once common throughout southern {city#1}. Packs of more than a hundred {plural_animal#1} would roam the grasslands. Today, only about 5,000 wild {plural_animal#1} remain in {city#1}.

Wild {plural_animal#1} are endangered because human development has destroyed much of their habitat. As a result, the {plural_animal#1} often leave wild areas to search for {plural_noun} bringing them into contact with {plural_noun}.

Because many farmers worry that wild {plural_animal#1} will hurt farm animals, farmers sometimes don't like the {plural_animal#1}. Plus, close contact with people has made some wild {plural_animal#1} catch diseases like {disease}.

Now, scientists think that they can use {liquid#1} to keep wild {plural_animal#1} safe. The {liquid#1} could keep them in wilderness areas and away from people.

Pet {plural_animal#1} mark their territory by spraying {plural_noun} and other landmarks with {liquid#1}. The scent in the {liquid#1} works like a secret language. It can tell other {plural_animal#1} messages like "keep out."

Wild {plural_animal#1} in {city#1} use a similar method. Researchers call it "{adjective#1} communication."

Using {adjective#1} communication, researchers hope to tell wild {plural_animal#1} to stay out of areas with people. The researchers call the method a "BioFence."

Researchers are beginning to experiment with a BioFence in southern {city#1}. They will sprinkle the {liquid#1} to see if it affects where wild {plural_animal#1} roam.

"[Wild {plural_animal#1}] are magnificent beasts. ... I sincerely hope the experiment works," environmentalist {person} from {city#1} said.

If it does work, researchers think that they may be able to create a BioFence for {plural_animal} and other animals too!`;
