import React, { useState } from "react";
import styled from "styled-components";

interface Props {}

export const LoginPage: React.FC<Props> = ({}) => {
  const [busy, setBusy] = useState<boolean>(false);

  return (
    <StyledContainer>
      <p>TODO login form goes here with notepad code</p>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
