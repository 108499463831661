import React from "react";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { appTheme } from "../../styles/appTheme";
import { muiThemeCustomized } from "../../styles/muiThemeCustomized";
import { SnackbarContextProvider } from "./SnackbarContextProvider";
import { AuthContextProvider } from "../auth/AuthContextProvider";
import { StoryContextProvider } from "../story/StoryContextProvider";

interface Props {
  children: React.ReactNode;
}

/**
 * Encapsulates the deep nesting of context providers (which there is currently no way of avoiding in this version of React).
 * These Context Providers are for retaining application-wide state.
 *
 * Other context providers may be found at deeper levels of the site where they only
 * need to retain ephemeral state within a deeper branch of the application.
 *
 * @param children
 * @constructor
 */
export const CombinedContextProviders: React.FC<Props> = ({ children }) => (
  <MUIThemeProvider theme={muiThemeCustomized}>
    <StyledComponentsThemeProvider theme={appTheme}>
      <SnackbarContextProvider>
        <AuthContextProvider>
          {/*<NavContextProvider>*/}
          <StoryContextProvider>
            {children}
            {/*</NavContextProvider>*/}
          </StoryContextProvider>
        </AuthContextProvider>
      </SnackbarContextProvider>
    </StyledComponentsThemeProvider>
  </MUIThemeProvider>
);
