import React, { useState } from "react";

export interface AuthContextProps {
  isAuthenticated: () => boolean;
}

export const AuthContext = React.createContext<AuthContextProps>({
  // TODO this probably isn't what we need
  isAuthenticated: () => true,
});

// github.com/deeppatel234/react-context-devtool
AuthContext.displayName = "AuthContext";

interface Props {
  children: React.ReactNode;
}

/**
 * @param props
 * @constructor
 */
export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  // const [message, setMessage] = useState<React.ReactNode>("");

  // TODO useReducer

  const store: AuthContextProps = {
    isAuthenticated: () => true,
  };

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
};
