import React, { useState } from "react";
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { questionsMap } from "../questions";
import { TokenQuestion } from "../model/TokenQuestion";
import { Section } from "./Section";
import { commonMuiSpacing } from "../styles/settings";
import styled from "styled-components";

interface Props {
  tokenQuestion: TokenQuestion;
  onQuestionResponse: (value: string) => void;
  remaining: number;
}

export const QuestionPrompt: React.FC<Props> = ({
  tokenQuestion,
  onQuestionResponse,
  remaining,
}) => {
  const { label, hint } = questionsMap[tokenQuestion.questionId];
  const [error, setError] = useState<boolean>(false);
  const [responseText, setResponseText] = useState<string>("");

  const reset = () => {
    setError(false);
    setResponseText("");
  };

  const onClickContinue = () => {
    if (responseText.length > 0) {
      onQuestionResponse(responseText);
      reset();
    } else {
      setError(true);
    }
  };

  return (
    <Section>
      <Grid container spacing={commonMuiSpacing} direction={"column"}>
        <Grid item>
          <TextField
            variant="outlined"
            fullWidth
            autoFocus
            label={label}
            value={responseText}
            error={error}
            helperText={error ? "Required" : undefined}
            onChange={(event) => setResponseText(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                // event.preventDefault();
                onClickContinue();
              }
            }}
          />
          {hint && !error && <FormHelperText>Hint: {hint}</FormHelperText>}
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={commonMuiSpacing}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Typography variant={"body2"}>
                {remaining} questions remaining
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                onClick={onClickContinue}
                color={"primary"}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

const Foo = styled.div`
  height: 100%;
`;
