import { StoryActions, StoryActionType } from "./storyActions";
import { TokenQuestion } from "../../model/TokenQuestion";
import { getTokensFromStoryTemplate } from "../../helpers/storyHelper";

export interface StoryState {
  storyTemplate: string;
  tokenQuestions: TokenQuestion[];
  questionIndex: number;
  allQuestionsAnswered: boolean;
}

export const initialStoryState: StoryState = {
  storyTemplate: "",
  tokenQuestions: [],
  questionIndex: 0,
  allQuestionsAnswered: false,
};

export function storyReducer(
  state: StoryState,
  action: StoryActions
): StoryState {
  switch (action.type) {
    case StoryActionType.GetStoryTemplateComplete:
      return {
        ...state,
        storyTemplate: action.payload,
        tokenQuestions: getTokensFromStoryTemplate(action.payload),
      };

    case StoryActionType.UserResponseReceived:
      const tokenQuestions = state.tokenQuestions.map((tokenQuestion, i) => {
        if (i === state.questionIndex) {
          return { ...tokenQuestion, userResponse: action.payload };
        } else {
          return tokenQuestion;
        }
      });

      const allQuestionsAnswered = tokenQuestions.every((q) => q.userResponse);

      return {
        ...state,
        // update the user response for the current question based on questionIndex
        tokenQuestions,
        questionIndex: allQuestionsAnswered
          ? state.questionIndex
          : state.questionIndex + 1,
        allQuestionsAnswered,
      };

    case StoryActionType.Reset:
      return {
        ...initialStoryState,
      };

    default:
      return {
        ...state,
      };
  }
}
