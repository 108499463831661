import { createTheme, ThemeOptions } from "@material-ui/core/styles";

/**
 * Use this to override default Material UI styles
 *
 * Default theme explorer: https://material-ui.com/customization/default-theme/?expand-path=$.palette
 *
 * @see /src/client/styles/appTheme for custom app theme properties that are not part of the Material UI theme
 */
const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#5d9732",
    },
    secondary: {
      main: "#5c422c",
    },
    background: {
      default: "#ffcc33",
      paper: "#ffffff",
    },
    success: {
      main: "#0fa94f",
    },
    info: {
      main: "#4bbbeb",
    },
    warning: {
      main: "#ff6634",
    },
    error: {
      main: "#d93c2d",
    },
    text: {
      primary: "rgba(0,0,0,0.68)",
    },
  },

  typography: {
    // fontFamily: "'Yantramanav', sans-serif",
    // h1: {
    //   fontSize: "32px",
    //   fontWeight: "bold",
    // },
    // h2: {
    //   fontSize: "24px",
    //   fontWeight: "bold",
    // },
    // h3: {
    //   fontSize: "20px",
    //   fontWeight: "bold",
    // },
    // h6: {
    //   fontWeight: "bold",
    // },
    // body1: {
    //   fontSize: "16px",
    // },
    // body2: {
    //   fontSize: "14px",
    // },
    // subtitle1: {
    //   fontSize: "12px",
    // },
    // subtitle2: {
    //   fontSize: "10px",
    //   textTransform: "uppercase",
    //   fontWeight: "bold",
    // },
  },
};

export const muiThemeCustomized = createTheme(themeOptions);
