import React, { useContext } from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { StoryContext } from "../context/story/StoryContextProvider";
import { Loading } from "./Loading";
import { StoryActionType } from "../context/story/storyActions";
import { QuestionPrompt } from "./QuestionPrompt";
import { FinishedStory } from "./FinishedStory";
import { renderStory } from "../helpers/storyHelper";
import { commonMuiSpacing } from "../styles/settings";
import { style } from "@material-ui/system";
import styled from "styled-components";

interface Props {}

export const GamePage: React.FC<Props> = ({}) => {
  const { state, dispatch } = useContext(StoryContext);
  const tokenQuestion = state.tokenQuestions[state.questionIndex];

  const onQuestionResponse = (value: string) => {
    dispatch({ type: StoryActionType.UserResponseReceived, payload: value });
  };

  const onClickRestart = () => {
    dispatch({ type: StoryActionType.Reset });
  };

  const storyTemplateSelected = state.storyTemplate.length > 0;

  return (
    <Container maxWidth={"md"}>
      <Banner>
        <Typography variant={"h5"}>Zoobaloo™</Typography>
      </Banner>
      {!storyTemplateSelected && <Loading />}
      {storyTemplateSelected && !state.allQuestionsAnswered && (
        <Box mt={commonMuiSpacing}>
          <QuestionPrompt
            tokenQuestion={tokenQuestion}
            onQuestionResponse={onQuestionResponse}
            remaining={state.tokenQuestions.length - state.questionIndex}
          />
        </Box>
      )}
      {state.allQuestionsAnswered && (
        <Box mt={commonMuiSpacing}>
          <FinishedStory
            story={renderStory(state.storyTemplate, state.tokenQuestions)}
            onClickRestart={onClickRestart}
          />
        </Box>
      )}
    </Container>
  );
};

const Banner = styled.div`
  margin-top: ${(props) => props.theme.mui.spacing(1)}px;
  background-color: #ff6633;
  color: #fff;
  text-align: center;
`;
