import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  //body, html {
  //  display: flex; height: 100%; width: 100%; 
  //  border: 2px dotted red
  ////  background-color: #FFCC33;
  //}
  form {
    // removes margin below form element
    margin-block-end: auto;
  }
`;
