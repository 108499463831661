import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import { AppRoutePathEnum } from "../model/AppRoutePathEnum";
import { AuthContext } from "../context/auth/AuthContextProvider";
import { GamePage } from "./GamePage";
import { LoginPage } from "./Login";
import { WelcomePage } from "./WelcomePage";

interface Props {}

export const AppRouter: React.FC<Props> = ({}) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route
          exact
          path={AppRoutePathEnum.SignIn}
          render={({ location }) =>
            isAuthenticated() ? (
              <Redirect to={AppRoutePathEnum.Game} />
            ) : (
              <LoginPage />
            )
          }
        />

        <PrivateRoute exact path={AppRoutePathEnum.Welcome}>
          <WelcomePage />
        </PrivateRoute>

        <PrivateRoute exact path={AppRoutePathEnum.Game}>
          <GamePage />
        </PrivateRoute>

        {/* route guard example */}
        {/* {canViewReports(role) && ( */}
        {/*  <Route path={AppRoutePathEnum.Reports}> */}
        {/*    <Reports /> */}
        {/*  </Route> */}
        {/* )} */}

        <Route
          path="*"
          // render={({ location }) => (signedIn ? <Redirect to={AppRoutePathEnum.GamePage} /> : <LoginPage />)}
          render={({ location }) => {
            console.log("*** catch-all route");
            return isAuthenticated() ? (
              <Redirect to={AppRoutePathEnum.Welcome} />
            ) : (
              <Redirect to={AppRoutePathEnum.SignIn} />
            );
          }}
        />
      </Switch>
    </Router>
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() ? children : <Redirect to={AppRoutePathEnum.SignIn} />
      }
    />
  );
};
