import { InsightfullTheme } from "../model/InsightfullTheme";
import { muiThemeCustomized } from "./muiThemeCustomized";

export const appTheme: InsightfullTheme = {
  mui: { ...muiThemeCustomized },
  custom: {
    formGridSpacing: 3,
  },
};

/*
{
  formGridSpacing: 3;
  insightfullLogoUrl: string;
  utilityNav: {
    width: string;
    };
  primaryNav: {
    widthCollapsed: string;
    widthExpanded: string;
  };
  contentBGColor: string;
}

is not assignable to type

{
  exampleBorder: string;
  formGridSpacing: GridSpacing;
  insightfullLogoUrl: string;
  primaryNav: {
    widthCollapsed: string;
    widthExpanded: string;
  };
  contentBGColor: string;
}
*/
