import React, { Reducer, useEffect, useReducer } from "react";
import { storyReducer, StoryState, initialStoryState } from "./storyReducer";
import { StoryContextStore } from "./StoryContextStore";
import { StoryActions, StoryActionType } from "./storyActions";
import { getRandomEl } from "../../utils/arrayUtils";
import { africaDogs } from "../../data/stories/africaDogs";
import { americanPie } from "../../data/stories/americanPie";
import { howToCatchAStar } from "../../data/stories/howToCatchAStar";
import { oliver } from "../../data/stories/oliver";
import { oneDay } from "../../data/stories/oneDay";

export const StoryContext = React.createContext<StoryContextStore>({
  state: initialStoryState,
  dispatch: () => undefined,
});

// https://github.com/deeppatel234/react-context-devtool
StoryContext.displayName = "StoryContext";

interface Props {}

/**
 * Encapsulates state logic for the Caregivers within Patient Profile view
 *
 * @param props
 * @constructor
 * @see NSString
 */
export const StoryContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<StoryState, StoryActions>>(
    storyReducer,
    initialStoryState
  );

  // TODO Stop hardcoding this.
  //    Instead, load options from server, display them, let user pick which one, then do this with the content of the one selected:
  const stories: string[] = [
    // `The {person_name#1} is {adjective}. Yay, {person_name#1}!`,
    // `I like a good {noun#1}. Have you ever seen a {noun#1}?`,
    // `I have a {bodypart} and I like going to {city}`,
    africaDogs,
    americanPie,
    howToCatchAStar,
    oliver,
    oneDay,
  ];

  // try {
  //   stories.forEach((storyTemplate) => validateStory(storyTemplate));
  //   console.log("*** foo");
  // } catch (err) {
  //   console.log("*** Story validation error: ", err);
  // }

  useEffect(() => {
    const main = async () => {
      try {
        dispatch({
          type: StoryActionType.GetStoryTemplateComplete,
          // payload: `The {adjective} {animal#1}, named {name#1} jumps over the {adjective} {plural_noun}. {name#1} the {animal#1} was very proud.`,
          payload: getRandomEl<string>(stories),
        });
      } catch (error) {
        console.log("*** error: ", error);
      }
    };

    main();
  }, [state.storyTemplate]);

  const store: StoryContextStore = {
    state,
    dispatch,
  };

  return (
    <StoryContext.Provider value={store}>{children}</StoryContext.Provider>
  );
};
