import { Question } from "./model/Question";
import { MapOf } from "./types";

export const questions: Question[] = [
  {
    id: "noun",
    label: "A noun",
    hint: "A thing. It can also be something that you can't touch like a cloud or a dream.",
  },
  {
    id: "adjective",
    label: "An adjective",
    hint: "A word that describes a thing, like yellow or happy",
  },
  {
    id: "adverb",
    label: "An adverb",
    hint: "A word that describes an action, like quickly",
  },
  {
    id: "plural_noun",
    label: "A plural noun",
    hint: "A thing, but more than one of them, like glasses",
  },
  {
    id: "animal",
    label: "An animal",
  },
  {
    id: "verb",
    label: "A verb",
    hint: "An action, like jump",
  },
  {
    id: "past_verb",
    label: "A past tense verb",
    hint: "An action than happened, like jumped",
  },
  {
    id: "doing_verb",
    label: "A doing verb",
    hint: "An action that's taking place, like running, usually ending in i-",
  },
  {
    id: "person_name",
    label: "A person's name",
  },
  {
    id: "bodypart",
    label: "A part of the body",
  },
  {
    id: "disease",
    label: "A disease",
    hint: "A sickness like chicken pox",
  },
  {
    id: "city",
    label: "A city or country",
  },
  {
    id: "number",
    label: "A number",
  },
  {
    id: "profession",
    label: "A profession",
    hint: "Like teacher or hockey player",
  },
  {
    id: "big_number",
    label: "A big number",
  },
  {
    id: "badguy",
    label: "A villain",
    hint: "Somebody bad like Count Dracula",
  },
  {
    id: "holiday",
    label: "A holiday",
    hint: "Like Christmas",
  },
  {
    id: "feeling",
    label: "A feeling",
    hint: "A way you could feel, like happy",
  },
  {
    id: "month",
    label: "A month",
  },
  {
    id: "relative",
    label: "A kind of relative",
    hint: "Like brother or sister",
  },
  {
    id: "plural_animal",
    label: "A plural animal",
    hint: "An animal, but more than one, like ducks",
  },
  {
    id: "liquid",
    label: "A kind of liquid",
  },
  {
    id: "person",
    label: "The name of a person",
  },
  {
    id: "male_name",
    label: "A male name",
  },
  {
    id: "female_name",
    label: "A female name",
  },
  {
    id: "place",
    label: "The name of a place",
    hint: "The name of anywhere, like school or even the mall",
  },
  {
    id: "game",
    label: "The name of a game",
    hint: "The name of a game, like Hide-And-Go-Seek or Go Fish",
  },
  {
    id: "vehicle",
    label: "A type of vehicle",
    hint: "A type of vehicle, like bike or car",
  },
  {
    id: "colour",
    label: "A colour",
    hint: "A colour, like red or green",
  },
];
/**
 * Key is question id (ex. verb)
 */
export const questionsMap: MapOf<Question> = questions.reduce(
  (itemsMap, item) => ({ ...itemsMap, [item.id]: item }),
  {}
);
