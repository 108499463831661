import React from "react";
import { Box, CssBaseline } from "@material-ui/core";
import { CombinedContextProviders } from "../context/providers/CombinedContextProviders";
import { AppRouter } from "./AppRouter";
import { GlobalStyles } from "../styles/GlobalStyles";

interface Props {}

export const App: React.FC<Props> = ({}) => {
  return (
    <CombinedContextProviders>
      <CssBaseline />
      <GlobalStyles />
      <Box display="flex" height="100%">
        <AppRouter />
      </Box>
    </CombinedContextProviders>
  );
};

export default App;
