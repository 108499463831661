import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Section } from "./Section";
import { commonMuiSpacing } from "../styles/settings";

interface Props {
  story: string;
  onClickRestart: () => void;
}

export const FinishedStory: React.FC<Props> = ({ story, onClickRestart }) => {
  return (
    <Section>
      <Grid container spacing={commonMuiSpacing}>
        <Grid item>
          <Typography variant={"h6"}>Here is your story:</Typography>
        </Grid>
        <Grid item>
          <Typography>{story}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={"contained"}
            onClick={onClickRestart}
            color={"primary"}
          >
            Play Again
          </Button>
        </Grid>
      </Grid>
    </Section>
  );
};
