import React from 'react'
import { Paper } from '@material-ui/core'
import styled from 'styled-components'

interface Props {
  dataCy?: string
}

export const Section: React.FC<Props> = ({ children, dataCy }) => (
  <StyledPaper elevation={1} data-cy={dataCy}>
    {children}
  </StyledPaper>
)

const StyledPaper = styled(Paper)`
  flex: 1;
  padding: ${props => props.theme.mui.spacing(2)}px;
  min-height: 50px;
`
