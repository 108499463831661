import React, { useState } from "react";
import { Color } from "@material-ui/lab";

export interface SnackbarContextProps {
  snackbarOpen: boolean;
  hide: () => void;
  message: React.ReactNode;
  severity: Color;
  autoHideDuration: number; // ms
  showSnackbar: (
    message: React.ReactNode,
    severity: Color,
    p_autoHideDuration?: number
  ) => void;
}

export const SnackbarContext = React.createContext<
  Partial<SnackbarContextProps>
>({});

// github.com/deeppatel234/react-context-devtool
SnackbarContext.displayName = "SnackbarContext";

interface Props {
  children: React.ReactNode;
}

/**
 * Encapsulates Strings API functionality and state.
 *
 * @param props
 * @constructor
 * @see NSString
 */
export const SnackbarContextProvider: React.FC<Props> = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<React.ReactNode>("");
  const [autoHideDuration, setAutoHideDuration] = useState<number>(6000);
  const [severity, setSeverity] = useState<Color>("info");

  const showSnackbar = (
    p_message: React.ReactNode,
    severity: Color,
    p_autoHideDuration?: number
  ) => {
    setMessage(p_message);
    setSnackbarOpen(true);
    setSeverity(severity);
    setAutoHideDuration(p_autoHideDuration || 6000);
  };

  const hide = () => setSnackbarOpen(false);

  const store: SnackbarContextProps = {
    snackbarOpen,
    hide,
    message,
    autoHideDuration,
    severity,
    showSnackbar,
  };

  return (
    <SnackbarContext.Provider value={store}>
      {children}
    </SnackbarContext.Provider>
  );
};
