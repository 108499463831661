import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Section } from "./Section";
import { commonMuiSpacing } from "../styles/settings";

interface Props {
  onClickContinue: () => void;
}

export const WelcomeMessage: React.FC<Props> = ({ onClickContinue }) => {
  return (
    <>
      <Section>
        <Grid container spacing={commonMuiSpacing} direction={"column"}>
          <Grid item>
            <Typography variant={"h6"}>
              Zoobaloo™ is a fun and educational game of words.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant={"contained"}
              onClick={onClickContinue}
              color={"primary"}
            >
              Play Game
            </Button>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Section>
      <Box mt={commonMuiSpacing}>
        <Typography variant={"caption"}>
          (Based on the original game Mad Libs by Roger Price and Leonard
          Stern.)
        </Typography>
      </Box>
    </>
  );
};
