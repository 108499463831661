import { TokenQuestion } from "../model/TokenQuestion";
import { questionsMap } from "../questions";

/**
 * @param storyTemplate template containing tokens from the known set matching Game.id values in questions.ts
 */
export const getTokensFromStoryTemplate = (
  storyTemplate: string
): TokenQuestion[] =>
  [...storyTemplate.matchAll(/\{([a-z_]+)(#[0-9]{1}){0,1}\}/gi)].reduce(
    (filteredResults: TokenQuestion[], result) => {
      const [token, questionId, repeatId] = result;
      const repeating = repeatId !== undefined;
      const previouslyFound = filteredResults.findIndex(
        (item) => item.token === token
      );
      if (repeating && previouslyFound !== -1) {
        // skip repeating item if
        return [...filteredResults];
      } else {
        return [
          ...filteredResults,
          {
            questionId,
            token,
            repeating,
          },
        ];
      }
    },
    []
  );

/**
 * Gets story with template tokens replaced with responses.
 */
export const renderStory = (
  storyTemplate: string,
  answeredQuestions: TokenQuestion[]
): string =>
  answeredQuestions.reduce((story, answeredQuestion) => {
    return story.replace(
      new RegExp(
        answeredQuestion.token,
        // "greedy" RegEx flag
        answeredQuestion.repeating ? "g" : undefined
      ),
      answeredQuestion.userResponse ?? "?"
    );
  }, storyTemplate);

export const validateStory = (storyTemplate: string): boolean => {
  const tokenQuestions = getTokensFromStoryTemplate(storyTemplate);
  tokenQuestions.forEach((item) => {
    if (!(item.questionId in questionsMap)) {
      throw new Error(
        `${item.questionId} is not defined in question dictionary`
      );
    }
  });
  return true;
};
