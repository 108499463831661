import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { commonMuiSpacing } from "../styles/settings";
import styled from "styled-components";
import { useHistory } from "react-router";
import { WelcomeMessage } from "./WelcomeMessage";

interface Props {}

export const WelcomePage: React.FC<Props> = ({}) => {
  const history = useHistory();

  const onClickContinue = () => {
    history.replace("/game");
  };

  return (
    <Container maxWidth={"md"}>
      <Banner>
        <Typography variant={"h5"}>Zoobaloo™</Typography>
      </Banner>

      <Box mt={commonMuiSpacing}>
        <WelcomeMessage onClickContinue={onClickContinue} />
      </Box>
    </Container>
  );
};

const Banner = styled.div`
  margin-top: ${(props) => props.theme.mui.spacing(1)}px;
  background-color: #ff6633;
  color: #fff;
  text-align: center;
`;
