export enum StoryActionType {
  GetStoryTemplateComplete = "GetStoryTemplateComplete",
  // NextQuestion = "NextQuestion",
  UserResponseReceived = "UserResponseReceived",
  Reset = "Reset",
}

export interface GetStoryTemplateCompleteAction {
  type: StoryActionType.GetStoryTemplateComplete;
  payload: string;
}

// export interface NextQuestionAction {
//   type: StoryActionType.NextQuestion;
// }

export interface UserResponseReceivedAction {
  type: StoryActionType.UserResponseReceived;
  payload: string;
}

export interface ResetAction {
  type: StoryActionType.Reset;
}

export type StoryActions =
  | GetStoryTemplateCompleteAction
  | UserResponseReceivedAction
  | ResetAction;
